import { Button, Modal, Space, Form } from 'antd'
import React from 'react'
import { HospiceInput } from '../../input'
import { HospiceInputNolabel } from '../../InputNoLabel'

const SendMsgModal = ({visiblemodal,onClose}) => {
    const [form] = Form.useForm()

  return (
    <div>
        <Modal width={500} 
            className='shadow-c modal'  
            title='Send a message'
            open={visiblemodal} 
            onOk={onClose} 
            onCancel={onClose} 
            centered 
            footer={
                <Space style={{justifyContent:"end",marginTop:'15px'}} className="w-100">
                    <Button
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                    <Button 
                        block
                        type="primary"
                        danger
                        htmlType='submit'
                        // loading={loading}
                        onClick={()=>form.submit()}
                    >
                        Send
                    </Button>
                </Space>
            }
        >
            <Form
                    form={form}
                    layout="vertical"
                    initialValues={{
                        remember: true,
                    }}
                    // onFinish={onFinish}
                    className='pt-3'
                >
                   <HospiceInputNolabel
                        textArea
                        name='msg'
                        placeholder='Say something...'
                        value={form.getFieldValue("msg") || ''}
                    />
            </Form>            
        </Modal>
    </div>
  )
}

export {SendMsgModal}