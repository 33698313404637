import React, { useEffect, useState } from 'react'
import { Space, Card, Row, Col, Form, Typography, Button, Flex, Avatar, Divider, Checkbox, Drawer, TimePicker, DatePicker, Input } from 'antd'
import { HospiceInput } from '../input'
import { ModuleTopSmhead } from '../PageComponents'
import { apiCalls } from '../../shared/Apis'
import { useDispatch, useSelector } from 'react-redux'
import { actionsApi } from '../../shared'
import { MyInput } from '../Forms'
import moment from 'moment'

const { Text } = Typography

const AddViewPrescription = ({ prescription, onClose, patientid, getAddPatientDetail }) => {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [selectedServices, setSelectedServices] = useState([])
    const dispatch = useDispatch()
    const { addviewprescriptionDetail } = useSelector(state => state?.viewmedicineprescription)

    useEffect(() => {
        if (patientid) {
            dispatch(actionsApi?.getViewMedpresDetail({ 
                id: patientid?.id, 
                appointmentDate: patientid?.appointmentDate,
                status: patientid?.status,
            }))
            
        }
    }, [patientid])

    const onChange = (e) => {
        const { checked, value } = e.target
        setSelectedServices(prev =>
            checked ? [...prev, value] : prev.filter(service => service !== value)
        )
    }

    const updateCreateApi = async (formData) => {
        setLoading(true)
        try {
            const result = await apiCalls?.patientprescriptionApi?.updateCreateApi(formData)
            setLoading(false)
            getAddPatientDetail()
            if (result) onClose()
            form.resetFields()
        } catch (error) {
            console.error("Error updating data:", error)
        }
    }

    const onFinish = async () => {
        try {
            let formData = await form.validateFields()
            formData = {
                ...formData,
                patientID: addviewprescriptionDetail?.patientID,
                appointmentID: addviewprescriptionDetail?.appointmentID,
                appointmentDate: addviewprescriptionDetail?.appointmentDate,
                services: selectedServices,
            }
            updateCreateApi(formData)
        } catch (error) {
            console.error("Form validation error:", error)
        }
    }

    return (
        <Drawer
            onClose={onClose}
            open={prescription}
            width={1300}
            footer={false}
            closeIcon={false}>
            <div>
                <Card className='radius-12 border0 shadow-in'>
                    <Flex gap={'small'} className='mb-3'>
                        <Avatar
                            size={50}
                            icon={<img src={addviewprescriptionDetail?.image} width={120} />}
                        />
                        <div>
                            <Typography.Title level={5} className='m-0'>
                                {addviewprescriptionDetail?.firstName + ' ' + addviewprescriptionDetail?.lastName}
                            </Typography.Title>
                            <Typography.Text className='grayish fs-12'>
                                Patient
                            </Typography.Text>
                        </div>
                    </Flex>
                    <Card className='border0 shadow-in mb-3'>
                        <Flex vertical gap={15}>
                            <Typography.Text strong>
                                Instructions - by Admin
                            </Typography.Text>
                            <Divider className='m-0' />
                            <Flex gap={'small'}>
                                <Typography.Text strong>Refer to:</Typography.Text>
                                <Typography.Text>{'#' + addviewprescriptionDetail?.patientID.substr(0, 5)}</Typography.Text>
                            </Flex>
                            <Flex gap={'small'}>
                                <Typography.Text strong>Patient:</Typography.Text>
                                <Typography.Text>
                                    {addviewprescriptionDetail?.firstName + ' ' + addviewprescriptionDetail?.lastName}
                                </Typography.Text>
                            </Flex>
                            <Typography.Text>
                                {addviewprescriptionDetail?.instruction}
                            </Typography.Text>
                        </Flex>
                    </Card>
                    <Form
                        form={form}
                        layout="vertical"
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={onFinish}
                    >
                        <Space direction='vertical' size={15} className='w-100'>
                            <Card className='shadow-in border0 radius-12'>
                                <Row gutter={[16, 16]}>
                                    <Col span={24}>
                                        <div>
                                            <ModuleTopSmhead name='Details' />
                                        </div>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={8}>
                                        {
                                            patientid?.status === 2?
                                            <MyInput
                                                label='Check in Time'
                                                value={moment(addviewprescriptionDetail?.fromTime).format('hh mm a')}
                                                disabled
                                            />
                                            :
                                            <Form.Item
                                                name='fromTime'
                                                label="Check in Time"
                                                placeholder='Enter check in time'
                                                value={form.getFieldValue("fromTime") || ''}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please enter check in time',
                                                    },
                                                ]}
                                            >
                                                <TimePicker className='W-100' format={'hh mm a'} />
                                            </Form.Item>
                                        }
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={8}>
                                        {
                                            patientid?.status === 2?
                                            <MyInput
                                                label='Check out Time'
                                                value={moment(addviewprescriptionDetail?.toTime).format('hh mm a')}
                                                disabled
                                            />
                                            :
                                            <Form.Item
                                                name='toTime'
                                                label="Check out Time"
                                                placeholder='Enter check out time'
                                                value={form.getFieldValue("toTime") || ''}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please enter check out time',
                                                    },
                                                ]}
                                            >
                                                <TimePicker className='W-100' format={'hh mm a'} />
                                            </Form.Item>
                                        }
                                    </Col>
                                    <Col span={24}>
                                        <div>
                                            <ModuleTopSmhead name='Service' />
                                        </div>
                                    </Col>
                                    <Col span={24}>
                                        {
                                            patientid?.status === 2 ?
                                                <>
                                                    {
                                                        addviewprescriptionDetail?.services?.map((data,index) =>
                                                            <div key={index}>
                                                                <Flex gap={5} align='center'>
                                                                    <img src='/assets/icons/checkbox.png' width={18} />
                                                                    <Text>{data}</Text>
                                                                </Flex>
                                                            </div>
                                                        )
                                                    }
                                                </>
                                            :
                                            <Form.Item
                                                name='services'
                                                value={selectedServices}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please check service',
                                                    },
                                                ]}
                                            >
                                                <Flex vertical gap={'small'}>
                                                    <Checkbox value="Provide spiritual, mental and emotional support" onChange={onChange}
                                                        >
                                                        Provide spiritual, mental and emotional support
                                                    </Checkbox>
                                                    <Checkbox value="Assist in  funeral planning" onChange={onChange}>
                                                        Assist in  funeral planning
                                                    </Checkbox>
                                                    <Checkbox value="Assistance in obtaining clergy services." onChange={onChange}>
                                                        Assistance in obtaining clergy services.
                                                    </Checkbox>
                                                </Flex>
                                            </Form.Item>
                                        }
                                    </Col>
                                    <Col span={24}>
                                        {
                                            patientid?.status === 2?
                                                <HospiceInput
                                                    textArea
                                                    label='Comments'
                                                    value={addviewprescriptionDetail?.comments}
                                                    disabled
                                                />
                                            :
                                            <Flex vertical gap={0}>
                                                <HospiceInput
                                                    textArea
                                                    name='comment'
                                                    label='Comments'
                                                    placeholder='If you have provided any other services, please mention.'
                                                    value={form.getFieldValue("comment") || ''}
                                                />
                                                <Typography.Text className='fs-12 grayish block text-end'>Max. 2000 characters</Typography.Text>
                                            </Flex>
                                        }
                                    </Col>
                                </Row>
                            </Card>
                            <Card className='shadow-in border0 radius-12'>
                                {
                                    patientid?.status === 2?
                                        <HospiceInput
                                            textArea
                                            label='Patient condition'
                                            value={addviewprescriptionDetail?.patientCondition}
                                            disabled
                                        />
                                    :
                                    <Flex vertical gap={0}>
                                        <HospiceInput
                                            textArea
                                            name='patientCondition'
                                            label='Patient condition'
                                            placeholder='Add remarks (if any)'
                                            value={form.getFieldValue("patientCondition") || ''}
                                        />
                                        <Typography.Text className='fs-12 grayish block text-end'>Max. 2000 characters</Typography.Text>
                                    </Flex>
                                }
                            </Card>
                            <Space className="w-100" style={{ justifyContent: 'end' }}>
                                <Button onClick={onClose}>
                                    Cancel
                                </Button>
                                <Button
                                    type="primary"
                                    block
                                    htmlType="submit"
                                    loading={loading}
                                    disabled={patientid?.status === 2? true: false}
                                >
                                    Confirm
                                </Button>
                            </Space>
                        </Space>
                    </Form>
                </Card>
            </div>
        </Drawer>
    )
}

export { AddViewPrescription }
