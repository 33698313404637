import React, { useEffect, useState } from 'react'
import { Image, Space, Row, Col, Form, Typography,Dropdown, Card, Table, Button, Flex } from 'antd'
import { MyInput, ModuleTopHeading, ActionButton, SendMsgModal, ViewPateintDrawer, AddViewPrescription } from '../../components';
import { EyeOutlined, FilterOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom/dist';
import { useDispatch, useSelector } from 'react-redux';
import { actionsApi, TableLoader } from '../../shared';
import moment from 'moment';

const { Text } = Typography

let timer;

const Patients = () => {

    const [form] = Form.useForm()
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const { addpatientDetail, loadingAddPatientDetail, filters, pagination } = useSelector(state => state?.addpatient);
    const [ visible, setVisible ] = useState(false)
    const [ visiblemodal, setVisibleModal] = useState(false)
    const [ patientid, setPatientId] = useState(null);
    const [ prescription, setPrescription ] = useState(false)
    const [ order, setOrder ] = useState(1)
    const [ patientviewid, setPatientViewId ] = useState(null)


    const items = [
        {
            label: <a href="#">A-Z</a>,
            key: '0',
        },
        {
            label: <a href="#">Z-A</a>,
            key: '1',
        },
    ];
  
    useEffect(() => {
        dispatch(actionsApi?.getAddPatientDetail({ ...filters, ...pagination, pageNo: 1 }));
        form.setFieldsValue({ ...filters });
    }, []);

    const filter = (order) => {
        let data = form.getFieldsValue();
        dispatch(actionsApi?.getAddPatientDetail({ ...data, order, ...pagination, pageNo: 1 }));
        dispatch(actionsApi?.changeAddpatientDetailFilters({ ...data, order }));
    };

    const call = (pageNo = 1, pageSize = 10) => {
        dispatch(actionsApi?.getAddPatientDetail({ ...filters, pageNo, pageSize }));
    };

    function searchHandler(name, pageNo = 1, pageSize = 20) {
        dispatch(actionsApi?.getAddPatientDetail({ ...filters, name, pageNo, pageSize }));
    }


    const onClick = ({ key }) => {
        key = parseInt(key) + 1;
        setOrder(key);
        filter(key);
    };

    const debounce = (func, delay) => {
        return function(...args) {
            clearTimeout(timer);
            timer = setTimeout(() => {
                func.apply(this, args);
            }, delay);
        };
    };

    const debouncedSearchHandler = debounce(searchHandler, 400);
  
    const columns = [
        {
            title: 'Patient Name',
            dataIndex: 'firstName',
            render: (_,row) => `${row?.firstName.charAt(0).toUpperCase() + row?.firstName.slice(1)} 
                                ${row?.lastName.charAt(0).toUpperCase() + row?.lastName.slice(1)}`
        },
        {
            title: 'Age',
            dataIndex: 'age',
        },
        {
            title: 'Disease',
            dataIndex: 'note',
        },
        {
            title: 'Appointment Date',
            dataIndex: 'AppointmentDate',
            render: (AppointmentDate) => (
                <Text>
                    {
                        AppointmentDate ?
                         moment(AppointmentDate).format('MMM Do YYYY')
                        :
                        null
                    }
                </Text>
            ),
            width: 200
        },
        {
            title: 'Visit Time',
            dataIndex: 'fromTime',
            render: (_,row) => (
                <Text>
                    {row?.fromTime} - {row?.toTime}
                </Text>
            )
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (status) => {
                return (
                    status === 2 ? (
                        <Space align='center'>
                            <img src="/assets/icons/online.png" width={'12px'} alt="" />
                            <Text style={{transform:"translateY(-2px)",display:'block'}}>Visted</Text>
                        </Space>
                    ) : (
                        <Space>
                            <img src="/assets/icons/offline.png" width={'12px'} alt="" />
                            <Text style={{transform:"translateY(-2px)",display:'block'}}>Unvisited</Text>
                        </Space>
                    )
                );
            }
        },
        {
            title: 'Action',
            key: 'action',
            fixed: 'right',
            align:'right',
            width: 100,
            render: (_, row) => (
                <Space>                    
                    <ActionButton
                        title='View Patient'
                        icon={<EyeOutlined />}
                        onClick={() => { setVisible(true); setPatientViewId(row?.id)}}
                        type='ghost'

                    />
                    <ActionButton
                        title='Prescription'
                        icon={<Image src='/assets/icons/visit.png' width={18} preview={false} />}
                        onClick={() => { setPrescription(true); setPatientId({id:row?.id, appointmentDate: row?.AppointmentDate, status: row?.status})}}
                        type='ghost'
                    />
                </Space>
            ),
        },
    ];
    

  return (
    <div>
        <Card className='radius-12 border0 shadow-in'>
            <Row gutter={[12,12]}>
                <Col span={24}>
                    <ModuleTopHeading
                        name='Patients'
                    />
                </Col>
                <Col span={24}>
                    <Form form={form} layout="vertical">
                        <Flex gap={'small'} className='w-100' align='end'>
                            <MyInput
                                name='name'
                                label='Search'
                                placeholder='Search'
                                value={form.getFieldValue('name') || ''}
                                className='w-100'
                                onChange={(e) => debouncedSearchHandler(e.target.value, 1, 20)}
                            />
                            <Dropdown
                                menu={{
                                    items,
                                    onClick
                                }}
                                trigger={['click']}
                                arrow
                                icon={<FilterOutlined />}
                                className='margin-top'
                            >
                                <Button
                                    icon={<FilterOutlined />}
                                >
                                    Filter
                                </Button>
                            </Dropdown>
                        </Flex>
                    </Form>
                </Col>
                <Col span={24}>
                    <Table
                        size='large'
                        columns={columns}
                        dataSource={addpatientDetail}
                        scroll={{ x: 1000 }}
                        pagination={{
                            hideOnSinglePage: true,
                            total: pagination?.totalRecords,
                            pageSize: pagination?.pageSize,
                            defaultPageSize: pagination?.pageSize,
                            current: pagination?.pageNo,
                            size: "default",
                            pageSizeOptions: ['10', '20', '50', '100'],
                            onChange: (pageNo, pageSize) => call(pageNo, pageSize),
                            showTotal: (total) => <Button>Total: {total}</Button>,
                        }}
                        loading={{ ...TableLoader, spinning: loadingAddPatientDetail }}
                    />
                </Col>
            </Row>
        </Card>
        <ViewPateintDrawer
            visible={visible}
            patientviewid={patientviewid}
            onClose={() => { setVisible(false); setPatientViewId(null) }}
        />
        <AddViewPrescription 
            prescription={prescription}
            patientid={patientid}
            getAddPatientDetail={call}
            onClose={()=> { setPrescription(false); setPatientId(null) }}
        />
        <SendMsgModal 
            visiblemodal={visiblemodal}
            onClose={()=>setVisibleModal(false)}
        />
    </div>
  )
}

export {Patients}