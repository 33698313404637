import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { domainUrl } from '../../../constants'
import { checkAuthorization } from '../../../helpers';
import { message } from 'antd'

export const getViewMedpresDetail = createAsyncThunk('getViewMedStatistics',
    async (object, { dispatch, getState }) => {

        const {userToken}= checkAuthorization()
        dispatch(gettingViewMedDetail())
        var myHeaders = new Headers();
        myHeaders.append("Authorization", userToken)
        myHeaders.append("Content-Type", "application/json")
        myHeaders.append("pageNO", object?.pageNo)
        myHeaders.append("recordsPerPage", object?.pageSize)
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({id: object?.id, appointmentDate: object?.appointmentDate})
        };

        const url = object?.status === 2 ? '/viewVisitedDataChaplin' : '/viewChaplinInstruction'
        fetch(domainUrl + url , requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result?.success) {
                    dispatch(viewmedDetailResponse(result?.data))
                    console.log(result?.data)
                }
                else 
                    throw result?.message
            })
            .catch(error => { 
                dispatch(viewmedDetailClear())
                message.error(error) 
            })
    }
)


const ViewMediSlice = createSlice({
    name: 'viewmedicineprescription',
    initialState: {
        data: null,
        loading: false,
        addviewprescriptionDetail: null,
        loadingViewMedDetail: false,
    },
    reducers: {
        getPatientStatistics: state => {
            state.loading=true
            state.data=null
        },
        statisticsResponse: (state, action)=>{
            state.data= action.payload
            state.loading= false
        },
        statisticsClear: (state)=>{
            state.data= null
            state.loading= false
        },
        gettingViewMedDetail: state => {
            state.loadingViewMedDetail=true
            state.addviewprescriptionDetail=null
        },
        viewmedDetailResponse: (state, action)=>{
            state.addviewprescriptionDetail= action.payload
            state.loadingViewMedDetail= false
        },
        viewmedDetailClear: (state)=>{
            state.addviewprescriptionDetail= null
            state.loadingViewMedDetail= false
        }
    },
})
export const { 
    gettingaddboxStatistics, statisticsResponse, statisticsClear,
    gettingViewMedDetail, viewmedDetailResponse, viewmedDetailClear
} = ViewMediSlice.actions;
export default ViewMediSlice.reducer;